import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CookieConsent from "react-cookie-consent";

import stephanRind from "../assets/images/team/1.jpg"
import Logo from "../components/logo";
import { FaLinkedin, FaFacebookF, FaTwitter, FaMedium } from "react-icons/fa";
import MailchimpForm from "../components/MailchimpForm";

import bgImage from "../assets/images/news/3.jpg"
import areaImage from "../assets/images/portfolio/overview.png"
import building from "../assets/images/portfolio/building.png"
import firstImage from "../assets/images/portfolio/1.jpg"
import secondImage from "../assets/images/portfolio/2.jpg"
import thirdImage from "../assets/images/portfolio/3.jpg"
import fourthImage from "../assets/images/portfolio/4.jpg"

const Header = () => (
  <div class="bg-white">
    <div
        className="flex flex-row justify-between mx-auto w-screen bg-white container h-24"
        style={{
          position: "inherit"
        }}
      >
        <div className="h-24 w-24 mt-8 ml-8">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex flex-row justify-between mt-8 mr-6 ml-2 invisible sm:visible">
          <div className=" cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
            <a
              href="https://www.linkedin.com/company/brickmark-ag/"
              target="_blank"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
            <a href="https://www.facebook.com/BrickMarkAG/" target="_blank">
              <FaFacebookF />
            </a>
          </div>
          <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
            <a
              className="h-4 w-4"
              href="https://twitter.com/BrickmarkAG"
              target="_blank"
            >
              <FaTwitter />
            </a>
          </div>
          <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange">
            <a
              className="h-4 w-4"
              href="https://medium.com/Brickmark-Ag"
              target="_blank"
            >
              <FaMedium />
            </a>
          </div>
        </div>
      </div>
    </div>
)

const PortfolioSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const maxHeight = 700;

  return (
    <div class="">
      <Slider {...settings}>
        <div>
          <img class="ml-auto mr-auto" style={{maxHeight}} src={secondImage} alt=""/>
        </div>
        <div>
          <img class="ml-auto mr-auto" style={{maxHeight}}  src={firstImage} alt=""/>
        </div>
        <div>
          <img class="ml-auto mr-auto" style={{maxHeight}} src={thirdImage} alt=""/>
        </div>
        <div>
          <img class="ml-auto mr-auto" style={{maxHeight}} src={fourthImage} alt=""/>
        </div>
      </Slider>
    </div>
  );
}

const Footer = () => (
  <div className="w-full mt-48 md:mt-30 pt-8 relative" id="footer">
        <div
          className="bg-brickmark-blue absolute w-screen"
          style={{ transform: "skewY(-3deg)", top: "-3vh", height: 200 }}
        />
        <div class="bg-brickmark-blue w-screen">
          <div class="flex flex-row flex-wrap max-w-6xl mx-auto">
            <div className="bg-brickmark-blue pb-12 md:w-1/2">
              <h2 className="font-headline text-4xl md:text-5xl font-black leading-tight text-white opacity-90 text-center md:text-left">
                Keep Updated.
              </h2>
              <div className="flex flex-row ml-8 mt-12 opacity-90">
                <div className="flex flex-row justify-between w-64">
                  <a
                    href="https://www.linkedin.com/company/brickmark-ag/"
                    target="_blank"
                    className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                  >
                    <div className="text-white">
                      <FaLinkedin />
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/BrickMarkAG/"
                    target="_blank"
                    className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                  >
                    <div className="h-4 w-4 text-white">
                      <FaFacebookF />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/BrickmarkAG"
                    target="_blank"
                    className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                  >
                    <div className="h-4 w-4 text-white">
                      <FaTwitter />
                    </div>
                  </a>

                  <a
                    href="https://medium.com/Brickmark-Ag"
                    target="_blank"
                    className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                  >
                    <div className="h-4 w-4 text-white">
                      <FaMedium />
                    </div>
                  </a>
              </div>

              </div>
              <div className="mx-auto mr-8 ml-8">
                <MailchimpForm />
              </div>
            </div>
            <div class="md:w-1/2 w-full pb-16 mx-auto">
                <div className="flex flex-col mt-8 md:mt-20 justify-center">
                  <div className="text-center md:text-left">
                    <a
                      href="tel:+41415000140"
                      className="text-base text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">phone: </span>+41 41 500 01 40
                    </a>
                  </div>
                  <div className="text-center md:text-left mt-2">
                    <a
                      href="fax:+41415000141"
                      className="text-base text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">fax: </span>+41 41 500 01 41
                    </a>
                  </div>
                  <div className="text-center md:text-left mt-2">
                    <a
                      href="mailto:info@brickmark.net"
                      className="text-base text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">Email: </span>
                      info@brickmark.net
                    </a>
                  </div>
                </div>
                <div className="m-auto w-auto flex flex-col mt-8 justify-center">
                  <div className="text-center md:text-left">
                    <a
                      target="_blank"
                      href="docs/terms.pdf"
                      className="text-sm text-white opacity-90 cursor-pointer text-base"
                    >
                      Terms of Use
                    </a>
                  </div>
                  <div className="text-center md:text-left mt-2">
                    <a
                      target="_blank"
                      href="docs/privacy.pdf"
                      className="cursor-pointer text-sm text-white opacity-90 text-base"
                    >
                      Privacy Notice
                    </a>
                  </div>
                  <div className="text-center md:text-left mt-2">
                    <a
                      target="_blank"
                      href="docs/legal.pdf"
                      className="cursor-pointer text-sm text-white opacity-90 text-base"
                    >
                      Imprint
                    </a>
                  </div>
                </div>
                <div className="mt-8 text-center md:text-left font-body text-xs text-white opacity-50">
                  <p className="">
                    BrickMark AG. <br /> Seestrasse 5, CH - 6300 Zug, Switzerland.
                  </p>
                  <p>© 2019 BrickMark AG. All Rights Reserved.</p>
                </div>
            </div>
          </div>
        </div>
    </div>
)

const ObjectInfo = () => (
  <div class="max-w-6xl flex flex-row flex-wrap-reverse mt-16 md:mt-24 pl-4 pr-4 md:pl-16 md:pr-16 mx-auto ">
    <div class="w-full md:w-2/4">
      <img class="w-4/5 ml-auto mr-auto mt-16 md:mt-8 " src={areaImage} alt="" />
    </div>
    <div class="w-full md:w-2/4 md:pl-8">
        <h2 class="font-bold text-2xl text-center md:text-left">
         The Area.
       </h2>

      <p class="mt-4 font-body font-light opacity-70 text-lg text-center md:text-left">
        The first investment of the BrickMark portfolio is the renowned Bahnhofstrasse 52, purchased for >CHF 130 million. The asset acquired in January 2020 is a commercial building in the central business district of Zurich with over 1’600 sqm of high-quality office and retail space. Located on one of the most visited and desirable boulevards in Europe – the Bahnhofstrasse.
      </p>
      <p class="mt-4 font-body font-light opacity-70 text-lg text-center md:text-left">
        Situated between central station and Lake Zurich, Bahnhofstrasse 52 (B52) is positioned to profit from extremely high pedestrian frequencies and the “brand” Bahnhofstrasse as one of the world’s global players among highstreets, while being the centre of Zurich's commercial district.
      </p>
    </div>
  </div>
)

const ObjectArea = () => (
  <div class="max-w-6xl mx-auto flex flex-row flex-wrap mt-16 md:mt-16 pl-4 pr-4 md:pl-16 md:pr-16">
    <div class="w-full md:w-2/4 md:pr-8">
      <h2 class="md:mt-32 md:mt-16 font-bold text-2xl text-center md:text-left">
        The Concept.
      </h2>
      <p class="mt-4 font-body font-light opacity-70 text-lg text-center md:text-left">
        Over the course of a 2-year construction period beginning early 2021, the whole building will be renovated and repositioned. By converting part of the office space in luxury retail space we expect to almost double the net rental income of the building by 2024.
      </p>
      <p class="mt-4 font-body font-light opacity-70 text-lg text-center md:text-left">
        B52 is the first object in the portfolio, demonstrating the ability  of the team’s and its partners finding rare off-market opportunities in highly demanded markets. The team has identified an additional USD 1bn pipeline of opportunities of similar profile.
      </p>
    </div>
    <div class="w-full md:w-2/4">
      <img class="w-3/5 ml-auto mr-auto mt-16 md:mt-32 " src={building} alt="" />
    </div>
  </div>
)

const LearnMore = () => (
  <div class="flex flex-row mx-auto mr-8 md:mr-0">
    <div class="relative mx-auto w-full max-w-6xl">
      <div class="absolute " style={{zIndex: "-999"}}>
        <img src={bgImage} alt="" class="mt-24 -ml-8 w-full md:w-7/12"></img>
      </div>
      <div class="mx-auto flex flex-row flex-wrap mt-40 ml-8 pb-8 border-gray-400 bg-white border w-full">
        <div class="pt-8 mt-1 w-full md:w-1/4">
          <img class="w-40 mx-auto" src={stephanRind} />
        </div>
        <div class="w-full md:w-3/4 text-center mr-2 ml-2 md:text-left md:ml-0 md:mr-0">
          <p class="mt-8 font-bold text-2xl text-lg text-brickmark-orange">
            Get in touch: <a href="mailto:assets@brickmark.net" class="font-bold">assets@brickmark.net</a>
          </p>
          <p class="mt-4 font-body font-light text-lg font-bold" >
            Stephan Rind
          </p>
          <p class="mt-4 font-body font-light text-lg">
            Entrepreneur and business angel, Stephan Rind started as an investment banker in New York and Dusseldorf. In 2002 he founded Colonia Real Estate and built up the company to be the 3rd largest listed residential real estate company in Germany. The company was bought by TAG in 2011.
          </p>
        </div>
      </div>
    </div>
  </div>
)

const isMobile = true

const PortfolioPage = () => (
  <Layout>
    <SEO title="Brickmark — Portfolio" />
    <Header />
    <div class="ml-auto mr-auto">

      <PortfolioSlider />

      <div>
        <h1 className="flex-row font-headline md:text-5xl text-4xl font-black leading-tight mt-24 text-center">
          Bahnhofstrasse 52
        </h1>
      </div>

      {/*
      <div class="w-screen pt-8 pb-8 mt-48 relative bg-brickmark-blue ">
        <div class="bg-brickmark-blue absolute w-screen" style={{ transform: "skewY(-3deg)", top: "-3vh", height: 200 }}></div>
        <div class="flex flex-row flex-wrap-reverse mt:16 md:mt-16 pl-16 pr-16">
          <div class="w-full md:w-2/4">
            <img class="h-64 ml-auto mr-auto mt-16 md:mt-40 " src="https://picsum.photos/seed/picsum6/1024/768" alt="" />
          </div>
          <div class="w-full md:w-2/4 md:pl-8">
            <h2 class="mt-32 font-body uppercase font-semibold tracking-wider text-center md:text-left">
              About //area.
            </h2>
            <p class="mt-8 font-body font-light opacity-70 text-lg text-center md:text-left">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
            </p>
          </div>
        </div>
      </div>
      */}
      <ObjectInfo />
      <ObjectArea />
      <LearnMore />
    </div>
    <Footer />
    <CookieConsent
            buttonText="Got It!"
            location="bottom"
            containerClasses="bg-brickmark-blue font-body font-light text-lg"
            buttonClasses="bg-transparent font-body font-normal text-white border-solid border-2 pl-2 pr-2 border-white"
            buttonStyle={{
              paddingTop: "6px",
              paddingLeft: "10px",
              marginRight: "20px",
            }}
          >
            This website uses cookies to ensure you get the best experience on
            our website.
          </CookieConsent>
  </Layout>
)

export default PortfolioPage;